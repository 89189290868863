import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
import Hero from "components/hero/HealthHero.js";

import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";

// import Footer from "components/footers/FiveColumnWithBackground.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import dev from  "../images/dev.jpg"; 
import sudhir from  "../images/sudhir.jfif"; 

import logo1 from "../images/pragmatiq-01.png";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} logo={logo1} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading> Our Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description: "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description: "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`
          }
        ]}
      /> */}
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],
            featured: true
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]
          }
        ]}
      /> */}
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        description = "In the realm of healthcare, our clients hold us in high regard, appreciating the exceptional medical care and personalized service we provide. Their trust in us is a reflection of our dedication to their well-being and our commitment to delivering quality healthcare services."
        testimonials={[
          {
            stars: 5,
            profileImageSrc: dev,
            heading: "Amazing User Experience",
            quote:
              "Pragmatiq has proven to be an excellent technology partner for ClinAlly. They seamlessly collaborated with our product team, efficiently collected user requirements, comprehended the scope of work, and consistently delivered the high-quality products on time, providing continuous support.",
            customerName: "Dr. Devraj Jindal",
            customerTitle: "CEO, Clinally"
          },
          {
            stars: 5,
            profileImageSrc: sudhir,
            heading: "Love the Developer Experience and Design Principles !",
            quote:
        "Pragmatiq is our technology partner since 2020. Team is professional and has in depth knowledge of subject. We have always seen that leadership believes in quality work and long term impact. They are not only the service provider but thought partners too.",
      customerName: "Sudhir Patel",
      customerTitle: "Co-Founder & CEO, Quinary Global"
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        description = "And we have got answers to all of them."
        faqs={[
          {
            question: "What kind of health facilities will be provided through this application ? ",
            answer:
              "It includes both public and private health facilities such as hospitals, clinics, labs, diagnostic centres, pharmacies, etc."
          },
          {
            question: "Do we have Consent form and does it works ?",
            answer:
              "You will be provided Consent form and can hide your particular problems . Highly secured and verification will be done through your permission to access your previous health record ."
          },
          {
            question: "Do we need to submit any supplementary documents necessary when applying or registering for this?",
            answer:
              "You need the government approved Aadhar Number  and mobile number to get registered "
          },
          {
            question: "Does this application provide a comprehensive health history?",
            answer:
              "Application will save complete history of health records and can be accessed at everywhere"
          },
          {
            question: "Do we need get any other appointment for the doctors consultancy?",
            answer:
              "You can book an appointment for doctors consultancy."
          },
          {
            question: "How secure is the app when it comes to handling and maintaining health records over the years?",
            answer:
              "It will not allow others to access your health records without your permissions."
          }
        ]}
      />
      {/* <GetStarted/> */}
      <Footer />
    </AnimationRevealPage>
  );
}
