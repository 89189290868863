
import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
// import backgroundImage from "../../images/smart-farming.jpg";
import logo1 from "../../images/pragmatiq.png";
import backgroundImage from "../../images/bgpic.png";
import backgroundVideo from '../../vedios/pqvideo.mp4';
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
 
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
 
const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  // background-image: url(${backgroundImage});
`;
 
 
// background-image: url("https://images.unsplash.com/photo-1536300007881-7e482242baa5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=80");
 
 
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;
 
const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;
 
const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
  small {
    ${tw`text-2xl text-center sm:text-3xl lg:text-4xl xl:text-5xl leading-snug -mt-24 sm:mt-0`}
  }
`;
 
const SmallText = tw.small`text-center sm:text-xl font-normal opacity-50 lg:text-base xl:text-base leading-snug -mt-24 sm:mt-0 font-black text-gray-100`;
 
const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:outline-none focus:shadow-outline`;
 
export default () => {
  const defaultLogoLink = (
    <LogoLink href="/">
      <img src={logo1} alt="logo" style={{opacity: '10',height: '100px', width: '200px'}}/>
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/aboutus">
        About
      </NavLink>
      <NavLink href="/healthcare">
        Healthcare
      </NavLink>
      <NavLink href="/education">
        Education
      </NavLink>
      {/* <NavLink href="/agriculture">
        Agriculture
      </NavLink>
      <NavLink href="/consulting">
        Consulting
      </NavLink> */}
      <NavLink href="/contactus">
        Contact Us
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      {/* <PrimaryLink href="/#">
        Careers
      </PrimaryLink> */}
    </NavLinks>
  ];
 
  return (
    <Container style={{position: 'relative'}}>
      <video src={backgroundVideo} autoPlay muted loop style={{position: 'absolute', zIndex : '-1', top: '0', width: '100%', height: '100vh', objectFit: 'cover', filter: 'brightness(350%)',opacity: '0.999'}}/>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader logoLink={defaultLogoLink} links={navLinks} />
        <Content>
          <Heading>
              <small>Sustainable technology company,</small>
              <br />
              <small>Venturing cutting-edge solutions</small>
              <br />
              <SmallText>in the realms of Healthcare, Education, Agriculture & Life Sciences.</SmallText>
          </Heading>
              {/* <small style={{fontWeight: 'bold', color: 'white', fontSize: '49px' }}>Sustainable technology company,
              <br/>Venturing cutting-edge solutions</small>
                <br/><small style={{ opacity: '0.5',color: 'white', fontSize: '30px' }} >in the realms of Healthcare, Education, Agriculture & Life Sciences.</small><br/><br/> */}
          {/* <PrimaryAction>Know More</PrimaryAction> */}
        </Content>
      </HeroContainer>
    </Container>
  );
};
 