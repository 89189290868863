import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "images/github-icon.svg";

import bharat from "../../images/bharat.png";
import ajay from "../../images/ajay.jpg";
import ram from "../../images/ram.jpg";
// import sunil from "../../images/sunil.jpg";
import suneel from "../../images/suneel.jpg";
import archana from "../../images/archana.png";
import sasikanth from "../../images/Sasikanth.png";

const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`mx-auto text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`;
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`;
const CardImage = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`w-64 h-64 bg-cover bg-no-repeat bg-center rounded`}
`;
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`;

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`bg-blue-500 text-white w-12 h-12`}
    }
  }
`;

export default ({
  heading = "Pragmatiq League",
  subheading = "Our Team",
  description = "Our Leadership team boasts nearly a century of collective experience in Healthcare, Life Sciences, and Educational domains.", //"Our Leadership team has combined experience close to a century in Healthcare, Life Sciences and Educational domains.",
  cards = [
    {
      imageSrc: bharat,
      position: "Founder & CEO",
      name: "Bharat Gujavarti",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/gbkreddy/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc: ajay,
      position: "Strategic Advisor",
      name: "Dr. Ajay Vamadevan",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/ajayvamadevan/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc: ram,
      position: "Strategic Advisor",
      name: "Ram Yeleswarapu",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/ram-yeleswarapu/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc: suneel,
      position: "Project Manager",
      name: "Suneel Pothuraju",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/suneel-pothuraju-77318812/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
    {
      imageSrc: archana,
      position: "Project Manager",
      name: "Archana Kadarla",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/kadarla-archana-92360292/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },

    {
      imageSrc: sasikanth,
      position: "Manager",
      name: "Sasikanth Reddy Vanga",
      links: [
        // {
        //   url: "https://twitter.com",
        //   icon: TwitterIcon,
        // },
        {
          url: "https://www.linkedin.com/in/sasikanth-reddy/",
          icon: LinkedinIcon,
        },
        // {
        //   url: "https://github.com",
        //   icon: GithubIcon,
        // },
      ],
    },
  ],
}) => {
  const [isHover, setIsHover] = useState(false);
  const [hoveredCardIndex, setHoveredCardIndex] = useState(-1);

  const handleMouseEnter = (index) => {
    setHoveredCardIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredCardIndex(-1);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <div
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                style={{position: hoveredCardIndex === index ? "relative" : "",}}
              >
                <CardImage
                  key={index}
                  style={{
                    filter: hoveredCardIndex === index ? "blur(8px)" : "",
                    backgroundColor: '#f2f2f2',
                  }}
                  imageSrc={card.imageSrc}
                />
                <CardLinks
                  style={{
                    display: hoveredCardIndex === index ? "block" : "none",
                    position: hoveredCardIndex === index ? "absolute" : "",
                    top: "50%",
                    left: "50%",
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </div>
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
