
import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import Footer from "components/footers/SimpleFiveColumn.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading style={{ fontSize: "2rem" }}>Terms & Conditions </Heading>
          </HeadingRow>
          <h6 style={{ fontSize: "1rem" }}>Last updated on Jan 22nd 2024</h6>
          <Text>
            {/* <div style={{lineHeight:'2', textAlign: 'justify'}}>  */}

            {/* <p>Pragmatiq.in (hereafter referred to as "Website") is an online platform which is owned and operated by PRAGMATIQ SYSTEMS PVT. LTD.  (hereafter referred to as the "Company"). The Website is an EDTECH platform for users to enroll into scholastic and life skill courses</p><br/>
            <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Terms and Conditions for the Use of the Website.</p>

           <p>There are certain terms and conditions that govern the use of this Website. </p>
           <p style={{ lineHeight: "1.5"}}>The "user" shall mean or include the user himself and/or any person authorized to use the Website on his/her behalf.</p>
           <p style={{ lineHeight: "1.5"}}>Please read the terms and conditions of this Website carefully before accessing and/or using this Website. By accessing this Website, the user is accepting to be bound by the terms and conditions of its use. The terms and conditions of use for this Website are subject to change and/or modifications by the Company at any time at its discretion and the website will have no obligation to inform the user about the change or modifications. The use of this Website after such changes/modifications in the terms and conditions are implemented constitutes acknowledgment and acceptance by the user of its changed and/ormodified terms and conditions.</p>
            <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Accessing the Website</p>
            <p style={{ lineHeight: "1.5"}}>The user agrees that he/she will use this Website for purposes which are in accordance with the laws prevalent in India, or the country from which the Website is being accessed. The user further agrees he/she will not use the Websitein any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
            <p style={{ lineHeight: "1.5"}}>The user agrees that he/she will not use the Website in any manner that may compromise or put in danger the security of the Website, render the Website inaccessible to other users or otherwise cause damage/harm to the Website or to itscontent. The user will not change and/or modify the content of the Website nor use the Website in a manner which will affect or interfere with the rights of third parties. All information provided by the user for the purpose of registration shall be accurate, correct, current and complete. If the Company believes that the information provided by the user is false and/or misleading, the Company shall be well within its rights to refuse the user access to the Website and/or to any of it resources, information etc for a temporary or permanent period.</p>
            <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Restrictions on the use of the Website</p>
            <p style={{ lineHeight: "1.5"}}>This Website is solely for personal use of the user. The user shall not use this Website for any other purpose, including any commercial purpose. The user shall not (i) co-brand this Website, or (ii) frame this Website, or (iii) hyper-link to this Website, without the express prior written permission of an authorized representative of the Company. For purposes of these Terms of Use, "co-branding" means to display a name, logo, trademark, or other means of attribution or identification of any party in such a manner so that an association may or is likely to be created by the user that such third party has been given the right by the Company to display, publish, or distribute such content on this Website. Intellectual Property Rights</p><br/>
            <p style={{ lineHeight: "1.5"}}>The Website displays various content including text,films, photographs and other images and sound, which is protected by copyright and/or other intellectual property rights. All copyright and other intellectual property rights on this Website belong to and are owned by the Company. The Company retains all rights, title and interest in the content displayed on the Website unless otherwise specifically stated. Therefore the content available on the Website shall not be copied, distributed, republished, uploaded, posted, circulated or transmitted in any way without the prior written consent of Company, or unless authorized in writing by the Company or unless specifically stated.</p>
            <p>The Company and Website respects the intellectual property of others, and expect the user to do the same.</p><br/>
            <p style={{ lineHeight: "1.5"}}>The Website shall acknowledge the intellectual property rights of third parties oncontent created by such third parties. The user shall not infringe the intellectual property rights including the copyright of such third party which is displayed on the Website and in case the user infringes such intellectual property rights of third parties, he/she will indemnify the Company for any loss, damage that may arise by way of proceedings, civil and/or criminal that may be filed against it as a result of the user's use of the Website or infringement of a third party's intellectual property rights or for any loss or damage to the Company that may arise by such infringement.</p>
             <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Hyper-links</p>
            <p style={{ lineHeight: "1.5"}}>The Website may contain hyper links to other websites which are not maintained by, or related to, the Company. However the Company does not endorse or recommend such Website or thecontent and the Company is not responsible or liable for the websites or content therein.</p>
            <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Age</p>
            <p style={{ lineHeight: "1.5"}}>No person below the age of 12 is permitted access to this Website or to register on this Website. The Company, however, shall not be held liable and/or responsible if it is found that a person below the age of 15 has been using the Website. The Company is not responsible or liable for the user's use of the internet nor for any problems, viruses etc that may occur while using the Website or on downloading any content from this Website.</p>
            <p  style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}> General Representation and Warranty</p>
             <p style={{ lineHeight: "1.5"}}>The user will use this Website strictly in accordance with the terms and conditions displayed on this Website and in accordance with all applicable laws of the country from where the user is accessing this Website.</p>
             <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Indemnification</p>
             <p style={{ lineHeight: "1.5"}}>The user agrees to indemnify and hold harmless the Company and subsidiaries, affiliates, licensors, content providers, service providers, employees, agents, officers, directors, and contractors from and against any and all claims and expenses, loss and damage, proceedings, civil and/or criminal, including advocate's fees, arising out of the user's use or unauthorized use of the Website, including but not limited to the violation of the terms and conditions of this Agreement herein. The user shall also indemnify the Company and the aforementioned persons from and against any claims, civil and/ or criminal, brought by third parties arising out of the user's use of the information accessed from this Website.</p><br/>
             <p>The user also agrees to hold harmless the Company from any claims resulting from any action taken by the Company during or as a result of its investigations and/or from any actions taken as a consequence of investigations by either the Company or law enforcement authorities.</p>
             <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Breach of Terms and Conditions</p>
             <p style={{ lineHeight: "1.5"}}>Without prejudice to the Company's other rights available under applicable law, if the user breaches any terms or conditions of the Agreement herein, the Company will be well within its right to take such action as it deems appropriate to deal with the breach, including but not limited to suspending or prohibiting the user's access to the Website, blocking computers using his/her IP address from accessing the Website and/or bringing civil and/or criminal proceedings against the user.</p>
            <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Jurisdiction</p>
           <p style={{ lineHeight: "1.5"}}>By agreeing to the terms and conditions of this Website, the user agrees that any dispute arising out of or relating to the Website shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of courts in Delhi.</p>
            <p style={{color:'green',fontFamily:'cursive',fontSize:'1.5rem'}}>Disclaimer</p>
            <p style={{ lineHeight: "1.5"}}>The Company makes no express or implied warranties, representations or endorsements whatsoever with respect to the Website, service or the content displayed on the Website. The Company also expressly disclaims all warranties/guarantees with respect to the service provided by the Experts in the field of scholastic and life skills content. The Company does not warrant the accuracy or completeness of the content, including the content relating to the Experts.</p><br/>
            <p  style={{ lineHeight: "1.5"}}>The Company and Website make no representation or warranty whatsoever as to the willingness or ability of any Expert to give advice, or as to whether the user shall find the advice given by an Expert to his or her satisfaction or whether the Expert will provide any solution to his/her problem or whether the Expert is in a position to deal with the problems of the user.</p><br/>
          <p  style={{ lineHeight: "1.5"}}>The Company and the Website do not recommend any specific Expert or professional, and the user is advised to check the credentials of the Expert him/herself if in doubt before using the services of the Experts as the Company will not be liable or responsible for any acts of omission or commission by the Expert/s.</p><br/>
          <p  style={{ lineHeight: "1.5"}}>A user is free to openly interact and share their concerns/queries/questions with either a specific expert or on a public level (if interested in seeking advice from multiple sources – other users and/or experts). A user is encouraged to be frank and open; however, at any point in time, if the content posted by a user is at all abusive, offensive, obscene or harmful in any way, that content shall be instantly removed from the Website and that user's account can be suspended without notice.</p> */}
            <div style={{ lineHeight: "1.5", textAlign: "justify" }}>
              <p>
                The following terms and conditions will be deemed to have been
                accepted by the User on usage of the website "
                <b>https://artifexarena.com/</b>". You are requested to read them
                carefully before you use the services of this site.{" "}
              </p>
              <p>
                The term User shall refer to the user who is browsing the site.
                The term{" "}
                <b style={{ fontSize: "0.1 rem" }}>
                  PRAGMATIQ SYSTEMS PRIVATE LIMITED
                </b>{" "}
                shall refer to <b>Purplegene app</b> 
                The term Site refers to <b>"https://artifexarena.com/" </b>owned and
                monitored by <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b>.{" "}
              </p>
              <ul>
                <li>
                  By using the Site, you agree to follow and be bound by the
                  following terms and conditions concerning your use of the
                  Site. <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app it may revise the
                  Terms of Use at any time without notice to you. Areas of the
                  Site may have different terms of use posted. If there is a
                  conflict between the Terms of Use and terms of use posted for
                  a specific area of the Site, the latter shall have precedence
                  with respect to your use of that area of the Site.{" "}
                </li>

                <li>
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app may terminate User's
                  access at any time for any reason. The provisions regarding to
                  disclaimer of warranty, accuracy of information, and
                  indemnification shall survive such termination.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app may monitor access to
                  the Site.{" "}
                </li>

                <li>
                  All content present on this site is the exclusive property of{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app. The software, text,
                  images, graphics, video and audio used on this site belong to{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app. No material from
                  this site may be copied, modified, reproduced, republished,
                  uploaded, transmitted, posted or distributed in any form
                  without prior written permission from{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app. All rights not
                  expressly granted herein are reserved. Unauthorized use of the
                  materials appearing on this site may violate copyright,
                  trademark and other applicable laws, and could result in
                  criminal or civil penalties.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app is a registered
                  trademark of <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> Limited.
                  This trademark may not be used in any manner without prior
                  written consent from <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b>{" "}
                  Limited.{" "}
                </li>

                <li>
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app does not make any
                  warranties, express or implied, including, without limitation,
                  those of merchantability and fitness for a particular purpose,
                  with respect to any information, data, statements or products
                  made available on the Site.
                </li>

                <li>
                  The Site, and all content, materials, information, software,
                  products and services provided on the Site, are provided on an
                  "as is" and "as available" basis.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app expressly disclaims
                  all warranties of any kind, whether express or implied,
                  including, but not limited to, the implied warranties of
                  merchantability, fitness for a particular purpose and
                  non-infringement.{" "}
                </li>

                <li>
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app shall have no
                  responsibility for any damage to User's computer system or
                  loss of data that results from the download of any content,
                  materials, information from the Site.{" "}
                </li>

                <li>
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b>  through Purplegene app may change or
                  discontinue any aspect of its website at any time, including,
                  its content or features.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app reserves the right to
                  change the terms and conditions applicable to use of the Site.
                  Such changes shall be effective immediately upon notice, which
                  shall be placed on the Site.{" "}
                </li>

                <li>
                  In no event will <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene appbe
                  liable for damages of any kind, including without limitation,
                  direct, incidental or consequential damages (including, but
                  not limited to, damages for lost profits, business
                  interruption and loss of programs or information) arising out
                  of the use of or inability to use{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b>'s through Purplegene app, or any
                  information provided on the website, or in the Products any
                  claim attributable to errors, omissions or other inaccuracies
                  in the Product or interpretations thereof. Some jurisdictions
                  do not allow the limitation or exclusion of liability.
                  Accordingly, some of the above limitations may not apply to
                  the User.{" "}
                </li>

                <li>
                  User agrees to indemnify, defend and hold{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app harmless from and
                  against all losses, expenses, damages and costs, including
                  reasonable attorneys' fees, arising out of or relating to any
                  misuse by the User of the content and services provided on the
                  Site.{" "}
                </li>

                <li>
                  The information contained in the Site has been obtained from
                  sources believed to be reliable.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app disclaims all
                  warranties as to the accuracy, completeness or adequacy of
                  such information.
                </li>

                <li>
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app makes no warranty
                  that: (a) the Site will meet your requirements; (b) the Site
                  will be available on an uninterrupted, timely, secure, or
                  error-free basis; (c) the results that may be obtained from
                  the use of the Site or any services offered through the Site
                  will be accurate or reliable.{" "}
                </li>

                <li>
                  The User's right to privacy is of paramount importance to{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app. Any information
                  provided by the User will not be shared with any third party.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> reserves the right to
                  use the information to provide the User a more personalized
                  online experience.{" "}
                </li>

                <li>
                  The Site provides links to web sites and access to content,
                  products and services from third parties, including users,
                  advertisers, affiliates and sponsors of the Site. You agree
                  that <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app is not
                  responsible for the availability of, and content provided on,
                  third party web sites. The User is requested to peruse the
                  policies posted by other web sites regarding privacy and other
                  topics before use. <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app is
                  not responsible for third party content accessible through the
                  App, including opinions, advice, statements and
                  advertisements, and User shall bear all risks associated with
                  the use of such content.{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app is not responsible
                  for any loss or damage of any sort User may incur from dealing
                  with any third party.{" "}
                </li>

                <li>
                  {" "}
                  For the purpose of these Terms and Conditions, The term "we",
                  "us", "our" used anywhere on this page shall mean{" "}
                  <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> through Purplegene app, whose
                  registered/operational office is D401, Vajra Sreenivasam,
                  Raghavendra Colony, Kondapur Hyderabad TELANGANA 500084 .
                  "you", "your", "user", "visitor" shall mean any natural or
                  legal person who is visiting our website and/or agreed to
                  purchase from us.
                </li>
              </ul>
              <p>
                Your use of the website and/or purchase from us are governed by
                following Terms and Conditions:
              </p>
              <ul>
                <li>
                  The content of the pages of this website is subject to change
                  without notice. Neither we nor any third parties provide any
                  warranty or guarantee as to the accuracy, timeliness,
                  performance, completeness or suitability of the information
                  and materials found or offered on this website for any
                  particular purpose. You acknowledge that such information and
                  materials may contain inaccuracies or errors and we expressly
                  exclude liability for any such inaccuracies or errors to the
                  fullest extent permitted by law.
                </li>
                <li>
                  {" "}
                  Your use of any information or materials on our website and/or
                  product pages is entirely at your own risk, for which we shall
                  not be liable. It shall be your own responsibility to ensure
                  that any products, services or information available through
                  our website and/or product pages meet your specific
                  requirements.
                </li>
                <li>
                  {" "}
                  Our website contains material which is owned by or licensed to
                  us. This material includes, but are not limited to, the
                  design, layout, look, appearance and graphics. Reproduction is
                  prohibited other than in accordance with the copyright notice,
                  which forms part of these terms and conditions.
                </li>
                <li>
                  {" "}
                  All trademarks reproduced in our website which are not the
                  property of, or licensed to, the operator are acknowledged on
                  the website.
                </li>
                <li>
                  Unauthorized use of information provided by us shall give rise
                  to a claim for damages and/or be a criminal offense.
                </li>
                <li>
                  {" "}
                  From time to time our website may also include links to other
                  websites. These links are provided for your convenience to
                  provide further information.
                </li>
                <li>
                  {" "}
                  You may not create a link to our website from another website
                  or document without <b>
                    PRAGMATIQ SYSTEMS PRIVATE LIMITED through Purplegene app
                  </b>{" "}
                  as prior written consent.
                </li>
                <li>
                  Any dispute arising out of use of our website and/or purchase
                  with us and/or any engagement with us is subject to the laws
                  of India .
                </li>
                <li>
                  {" "}
                  We, shall be under no liability whatsoever in respect of any
                  loss or damage arising directly or indirectly out of the
                  decline of authorization for any Transaction, on Account of
                  the Cardholder having exceeded the preset limit mutually
                  agreed by us with our acquiring bank from time to time.
                </li>
              </ul>
            </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
