import React, { useState, useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import "../../carousal.css";

import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import healthSDG from "../../images/clinally-mpower.png"; //
import educationSDG from "../../images/4.webp";
import agriSDG from "../../images/2.webp";
// import landLordzLogo from "../../images/landlordz.png";
import landlordz from "../../images/prj-landlordz.png";
import skanda from "../../images/skanda.png";
import purplegene from "../../images/prj-purplegene.png";
import padhobadho from "../../images/prj-padhobadho.png";
import pals from "../../images/prj-pals.png";
import empowerme from "../../images/prj-clinally-empower.png";
import aimhelthy from "../../images/prj-clinally-aim.png";
import cdss from "../../images/prj-clinally-cdss.png";
import Breads from "../../images/Breads.png";
// import ClinallyCompanyLogo from "../../images/clinallyCompanyLogo.svg";
import Udbhata from "../../images/Udbhata.png";
import ParivartanaLogo from "../../clientImages/Group 157.png";
import SkandaLogo from "../../clientImages/Group 241.png";
import aimHealthyLogo from "../../clientImages/image5.png";
import landlordzLogo from "../../clientImages/image6.png";
import clinallyLogo from "../../clientImages/image3.png";
import purplegeneLog from "../../clientImages/Group 240.png";
import padhobadhoLogo from "../../clientImages/Group 239.png";
import eIrbLogo from "../../clientImages/Group 148.png";
import agueilaLogo from "../../clientImages/Group 152.png";
import ClinallyCompanyLogo from "../../clientImages/image1.png";
import UdbhataLogo from "../../clientImages/image2.png";
import Sunbluelogo from "../../clientImages/image7.png";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-10 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-128 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const GridContainer = tw.div`grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 place-items-center gap-16 p-6`;
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose text-justify`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: healthSDG, //empowerme,
      subtitle: "SDG 3 - Good Health and Well Being (Healthcare) ",
      title: "Clinally mPower Health for Doctors",
      description:
        "ClinAlly mPower Health is a fully compliant ABDM (Ayushman Bharat Digital Mission) platform, intelligently designed to empower doctors in delivering superior patient care. This comprehensive application, grounded in scientific principles, offers evaluation, screening, investigations, clinical management, lifestyle advice, and follow-up scheduling for patients with hypertension and diabetes. Developed in collaboration with AIIMS, New Delhi, and CCDC, our platform embodies the cutting-edge fusion of technology and medical expertise.",
      //"ClinAlly mPower Health is an ABDM (Ayushman Bharat Digital Mission) compliant, Intelligent digital health platform designed to assist doctors in providing the best care to their patients. A holistic application based on science for evaluation, screening, investigations, clinical management, lifestyle advice, and follow-up scheduling of patients with hypertension and diabetes. This platform is created in collaboration with AIIMS, New Delhi and CCDC.",
      url: "https://app.clinally.com",
    },
    {
      imageSrc: aimhelthy,
      subtitle: "SDG 3 - Good Health and Well Being (Healthcare) ",
      title: "Clinally ai.m Healthy for all",
      description:
        "Clinally ai.m Healthy is an essential digital health locker that meticulously manages your comprehensive health records and those of your family, all in compliance with ABDM standards. It efficiently tracks doctor visits, fitness data, medications, and vital information. ai.m Healthy also offers up-to-date health updates and internationally approved health calculators. A digital Health Bro, genuinely dedicated to your well-being.", //"ClinAlly ai.m Healthy is a must have digital health locker that keeps track of all your and your family health records (from all your doctor visits and is ABDM compliant), fitness data (synced directly), medications, vital data. ai.m Healthy also provides latest health updates and have internationally approved health calculators. A digital Health Bro who cares about your health. ",
      url: "https://www.aimhealthy.app",
    },
    {
      imageSrc: cdss,
      subtitle: "SDG 3 - Good Health and Well Being (Healthcare) ",
      title: "Clinally Clincal Decision Support System (CDSS) for Doctors",
      description:
        "ClinAlly CDSS, a culmination of years of rigorous research by esteemed doctors in collaboration with AIIMS, New Delhi, and CCDC, is an intelligent decision engine, offering valuable guidance to healthcare providers based on patients' clinical parameters. This pivotal resource facilitates task shifting and enables physicians to attend to a larger patient base. Currently encompassing modules for NCDs, TB, Osteo, Heart Failure, and COPD, ongoing research is dedicated to exploring additional medical conditions.",
      // "ClinAlly CDSS, result of years of reserach by doctors in collaboration with AIIMS, New Delhi and CCDC, is an intelligent decision engine that provides guidance to healthcare provides based on the clinical parameters of patients. This helps in task shifting and allows physicians to look after more patients. CDSS currentliy has modules for NCDs, TB, Osteo, Heart Failure, COPD. Research for other conditions is in progress.",
      url: "https://www.clinally.com",
    },

    {
      imageSrc: padhobadho,
      subtitle: "SDG 4 - Quality Education",
      title: "PadhoBadho",
      description:
        "Padhobadho, an innovative edtech start-up, is spearheading a transformative mission by revolutionizing education delivery in rural India. With a resolute ambition, Padhobadho seeks to offer relevant education to millions in small towns and villages, opening the doors to quality learning that was hitherto inaccessible. Crucially, Padhobadho is committed to achieving this vision through a user-friendly and easily understandable language, fostering inclusivity and equitable education for all.",
      // "Padhobadho is an innovative edtech start-up, revolutionizing education delivery in rural India. Embarking on an ambitious mission, Padhobadho aims to provide relevant education to millions in small towns and villages, granting access to quality learning that was hitherto inaccessible. Of utmost importance, Padhobadho intends to accomplish this through a straightforward and easily comprehensible language, ensuring inclusivity and equitable education for all.",//"PadhoBadho is an edtech start-up that is re-inventing education delivery in rural india. Padho Badho has undertaken an ambitious journey to impart relevant education to millions in small towns and villages who have hitherto been denied access to quality education. More importantly, PadhoBadho plans to do it in a simple and easy to understand language.",
      url: "https://www.padhobadho.com",
    },
    {
      imageSrc: pals,
      subtitle: "SDG 4 - Quality Education",
      title: "Pragmatiq Academy",
      description:
        //"An ongoing successful project since 2014, PALS (previously known as TALL), is an initiative focuses on financially under privileged, academically eligible students. This is a Learn while you Earn scheme, creating an upward career curse as first graduates. Focusing on Pharmaceutical Industry – an Skill development programme  with On the Job Training – Work integrated Training Programme - UGC Curriculum – Bachelors in Vocational Education. A non-formal educational methods to enhance earning capabilities and thereby improve livelihood conditions.",
        "PALS, formerly known as TALL, has been an ongoing and highly successful project since 2014. This initiative is dedicated to empowering financially deprived yet academically eligible students, offering them a unique 'Learn while you Earn' approach that paves the way for a promising career path as pioneering graduates. Centered around the Pharmaceutical Industry, this program provides a comprehensive Skill Development Program with a focus on On-the-Job Training and Work-Integrated Learning, aligning with the UGC Curriculum for a Bachelor's in Vocational Education. By employing effective non-formal educational methods, PALS enhances students' earning potential and possibilities, eventually uplifting the economic well-being of the less priviliged.",
      url: "https://pals.pragmatiq.in",
    },
    {
      imageSrc: purplegene,
      subtitle: "SDG 4 - Quality Education",
      title: "PurpleGene",
      description:
        "At PurpleGene, we firmly believe in the uniqueness of every individual, and we recognize that their learning capabilities are shaped by both nature and nurture. While current education and teaching methodologies make efforts to cater to individual needs, we are dedicated to taking personalization to the next level. By tailoring educational content and delivery to align with each person's distinctive traits, we envision significantly improved outcomes. Our approach holds the promise of unlocking better employability and fostering successful careers. PurpleGene serves as a dynamic and innovative platform designed to evaluate and optimize an individual's learning journey, empowering them to excel in areas where their true strengths lie. We are committed to nurturing talent, enabling personal growth, and unleashing the full potential of every individual who is an integral part of Purplegene community.", //"We belive that every individual is unique and the learning capabilities are determined by nature and nurture. While current education / teaching methodologies try to address individual needs to a certain extent, personalizing educational content and delivery based on the unique traits of individuals will enhance the outcomes. This will lead to a better employability and successful careers. PurpleGene is a creative platform that evaluates, enhances the learning of an individual and helps them excel in what they are good at.",
      url: "https://purplegene.in",
    },
    // {
    //   imageSrc:
    //   agriSDG,
    //   subtitle: "SDG 2 - Zero Hunger (Agriculture)",
    //   title: "Landify",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    //   url: "https://timerse.com"
    // },
    {
      imageSrc: landlordz,
      subtitle: "SDG 2 - Zero Hunger (Agriculture)",
      title: "LandLordz",
      description:
        "Agro-realty, albeit is a relatively recent concept in India, has been well-established in Western countries for quite some time. In nations like the USA and UK, people have been making land investments in agro-realty to reap the benefits of cultivating edible fruits and vegetables while bolstering their income. For some, it even transforms into a delightful recreational space to enjoy over the weekends. Investing in farmland is regarded as a secure option for preserving one's funds, although the returns on investment are typically higher than average, providing a sense of financial security for investors. At Landlordz, we understand the potential of agro investments and are here to guide you through the process in exploring different ways to enrich . Our expert team will help manage your agro-realty ventures, ensuring you connect with reliable service providers to achieve both security and sustainability. With Landlordz by your side, you can confidently delve into the world of agro-realty and experience the growth and rewards it offers.", //"The whole concept of agro-realty, albeit recent in India, has been around in the western countries for quite a long time. In USA and UK, people make land investments in agro-realty to leverage the benefits of growing edible fruits and vegetables and to supplement their income. For some, it even turns into a recreational space over the weekend. Farm investment is a safe option for storing one’s funds as the return on investment is usually higher than normal and also lends safety to investors’ money. Landlordz will help manage your agro investments and connects with service provides to achieve security and maintain sustainability.",
      url: "https://landlordz.in",
    },
  ];

  const img = [];

  const OurImages = styled.div((props) => [
    tw`mt-10 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row",
  ]);

  const clientNpartnerImages = [
    ParivartanaLogo,
    SkandaLogo,
    ClinallyCompanyLogo,
    landlordzLogo,
    UdbhataLogo,
    Sunbluelogo,
    padhobadhoLogo,
    eIrbLogo,
    // agueilaLogo,
  ];
  const ProductImageSlides = [
    purplegeneLog,
    padhobadhoLogo,
    clinallyLogo,
    aimHealthyLogo,
    eIrbLogo,
    landlordzLogo,
    agueilaLogo,
    UdbhataLogo,
    SkandaLogo,
  ];

  // const ClientImageSlides = () => {
  //   const images = [
  //     ParivartanaLogo,
  //     SkandaLogo,
  //     ClinallyCompanyLogo,
  //     landlordzLogo,
  //     UdbhataLogo,
  //     Sunbluelogo,
  //     padhobadhoLogo,
  //     eIrbLogo,
  //     // agueilaLogo,
  //   ];

  //   return (
  //     <div className="custom-carousel-container">
  //       <Carousel
  //         autoPlay={true}
  //         infiniteLoop={true}
  //         interval={2000}
  //         showThumbs={false}
  //         showStatus={false}
  //         showArrows={false}
  //         dynamicHeight={false}
  //         showIndicators={false}
  //         centerMode={true}
  //         centerSlidePercentage={33.33}
  //       >
  //         {images.map((image, index) => (
  //           <div key={index}>
  //             <img
  //               src={image}
  //               alt={`Image ${index + 1}`}
  //               style={{ width: "80%", height: "100%" }}
  //             />
  //           </div>
  //         ))}
  //       </Carousel>
  //     </div>
  //   );
  // };

  // const ProdiuctImageSlides = () => {
  //   const images = [
  //     purplegeneLog,
  //     padhobadhoLogo,
  //     clinallyLogo,
  //     aimHealthyLogo,
  //     eIrbLogo,
  //     landlordzLogo,
  //     agueilaLogo,
  //     Udbhata,
  //     SkandaLogo,
  //   ];

  //   return (
  //     <div className="custom-carousel-container">
  //       <Carousel
  //         autoPlay={true}
  //         infiniteLoop={true}
  //         interval={2000}
  //         showThumbs={false}
  //         showStatus={false}
  //         showArrows={false}
  //         dynamicHeight={false}
  //         showIndicators={false}
  //         centerMode={true}
  //         centerSlidePercentage={33.33}
  //       >
  //         {images.map((image, index) => (
  //           <div key={index}>
  //             <img
  //               src={image}
  //               alt={`Image ${index + 1}`}
  //               style={{ width: "80%", height: "100%" }}
  //             />
  //           </div>
  //         ))}
  //       </Carousel>
  //     </div>
  //   );
  // };

  const ImageSlider = ({ images }) => {
    if (!Array.isArray(images) || images.length === 0) {
      return <div>No images to display</div>; // Handle case when images are not defined or empty
    }

    const repeatedImages = [];
    for (let i = 0; i < 1000; i++) {
      repeatedImages.push(...images);
    }

    return (
      <div className="image-slider">
        <div className="slider-wrapper">
          {repeatedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              className="slide"
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Our Clients & Partners</HeadingTitle>
          {/* <div className="logos">
            <div className="logos-slide"> */}
          <ImageSlider images={clientNpartnerImages} />
          {/* </div>
          </div> */}
          <br />
        </HeadingInfoContainer>

        <HeadingInfoContainer>
          <HeadingTitle>Our Current Projects</HeadingTitle>
          <HeadingDescription>
            {/* We are working on innovative solutions that helps us and our clients achieve Sustainable Development Goals (SDGs) in Healthcare, Education, Agriculture and Life Sciences.  */}
            At the core of our endeavors lies a commitment to crafting
            innovative solutions for our esteemed clients, diligently adhering
            to Sustainable Development Goals (SDGs). Our comprehensive approach
            reflects a steadfast dedication to tackling pressing global
            challenges and nurturing a sustainable future for generations to
            come.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link target="_blank" href={card.url}>
                  See More Details
                </Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>

      <HeadingInfoContainer>
        <HeadingTitle>Our Products & Services</HeadingTitle>
        <ImageSlider images={ProductImageSlides} />
      </HeadingInfoContainer>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
