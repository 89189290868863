import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import mPowerHealthLogo from "../../images/mPowerHealth.png";
import healthCareVision from "../../images/healthcareVision.png";
import nationlHealth from '../../images/nationalHealth.png';
import aimHealth from '../../images/aimLogo.png'

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const OurMissionContainer = tw.div`md:flex gap-8 md:flex-col justify-center pt-10`;
const OurMissionDiv = tw.div`flex items-center gap-5`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const CategoriesSubheading = tw.h1`text-base md:text-xs lg:text-xl xl:text-3xl font-bold`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const PrimaryButton = styled(PrimaryButtonBase)((props) => [
  tw`mt-8 md:mt-8 text-sm inline-block mx-auto md:mx-0`,
  props.buttonRounded && tw`rounded-full`,
]);

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  description = "Expect top-notch results with quality work, meticulously designed and developed by our team of seasoned professionals. Our software service provider ensures that your project is crafted to perfection, meeting the highest industry standards.",
  primaryButtonText = "Learn More",
  primaryButtonUrl = "https://timerse.com",
  imageSrc = TeamIllustrationSrc,
  // imageSrc = healthCareVision,
  buttonRounded = true,
  imageRounded = true,
  imageHeight=true,
  imageBorder = false,
  imageShadow = false,
  imageCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        {/* <div style={{ display: "flex", alignItems: "center" }}> */}
          <ImageColumn>
            <Image
              css={imageCss}
              src={healthCareVision}
              // imageBorder={imageBorder}
              // imageShadow={imageShadow}
              imageRounded={imageRounded}
              style={{height: '60vh'}}
            />
            {imageDecoratorBlob && (
              <DecoratorBlob css={imageDecoratorBlobCss} />
            )}
          </ImageColumn>
          <TextColumn textOnLeft={textOnLeft}>
            <TextContent>
              <Subheading>{subheading}</Subheading>
              {/* <Heading>{heading}</Heading> */}
              <OurMissionContainer>
                <OurMissionDiv>
                  <img
                    src={mPowerHealthLogo}
                    style={{ width: "6rem" }}
                    alt=""
                  />
                  <div>
                    <CategoriesSubheading>
                      ClinAlly mPower Health App
                    </CategoriesSubheading>
                    <Description>
                      AI powered digital assistant for the doctors, in
                      collaboration with AIIMS and CCDC
                    </Description>
                  </div>
                </OurMissionDiv>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <img
                    src={aimHealth}
                    style={{ width: "6rem" }}
                    alt=""
                  />
               
                  <div>
                    <CategoriesSubheading>
                    ai.m Healthy App
                    </CategoriesSubheading>
                    <Description>
                    An App created by the doctors for your good health & wellbeing
                    </Description>
                  </div>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <img
                    src={nationlHealth}
                    style={{ width: "6rem" }}
                    alt=""
                  />
                  <div>
                    <CategoriesSubheading>
                    A.B.H.A. Registration Through ClinAlly
                    </CategoriesSubheading>
                    <Description>
                    ABHA - Ayushman Bharat Health Account or Health ID Card Approved by NDHM.GOV.IN
                    </Description>
                  </div>
                </div>
              </OurMissionContainer>
              {/* <Description>{description}</Description>
            <PrimaryButton
              buttonRounded={buttonRounded}
              as="a"
              href={primaryButtonUrl}
            >
              {primaryButtonText}
            </PrimaryButton> */}
            </TextContent>
          </TextColumn>
        {/* </div> */}
      </TwoColumn>
    </Container>
  );
};
