import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/support-icon.svg";
// import ShieldIconImage from "../../images/shield-icon.svg";
import Graduates from "../../images/graduates.png";
import Internships from "../../images/Internships.png";
import Training from "../../images/Training.jpg";
import GuestLectures from "../../images/guestLectures.png";
import IndustrialTraining from "../../images/IndustrialTraining.png";
import CustomizeIconImage from "../../images/customize-icon.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import padhoBhadoPortfolio from "../../images/padhoBadhoPortfolio.png";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    // {
    //   imageSrc: padhoBhadoPortfolio,
    //   title: "Padho Badho",
    //   description:
    //     "Padho Badho's partnership with Pragmatiq strengthens their educational offerings through collaborative expertise, marking a significant step in EdTech advancement.",
    // },
    {
      imageSrc: Training,
      title: "Purplegene",
      description:
        "Purplegene provides high-quality software training, internships for success in the ever-changing tech industry. Join them to enhance your skills and thrive in software development.",
    },
    {
      imageSrc: Graduates,
      title: "PALS",
      description:
        "Pragmatiq Academy of Life Sciences (PALS) addresses the skills and knowledge gap of the Life Sciences industry, thereby creating an equipped workforce that is job ready.",
    },
    {
      imageSrc: GuestLectures,
      title: "Featured Talks",
      description:
        "Pragmatiq is thrilled to present a series of captivating guest lectures featuring industry experts and thought leaders. Our Featured Talks provide an invaluable platform for students and professionals alike to immerse themselves in a dynamic learning environment.",
    },
    // {
    //   imageSrc: Internships,
    //   title: "Internships",
    //   description:
    //     "Interns at Purplegene succeed through their dedication, adaptability, and a passion for genomics, leaving with a profound understanding of the field and valuable connections for their future in genomics.",
    // },
    // {
    //   imageSrc: IndustrialTraining,
    //   title: "Industrial Training",
    //   description:
    //     "Pragmatiq is proud of your successful completion of our industrial training program, where you gained valuable skills and knowledge. We're excited to witness your future career, confident that you'll make a significant impact in your chosen field. Congratulations on your achievement!",
    // },
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>
          Our <span tw="text-primary-500">Portfolio</span>
        </Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              {/* <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span> */}
              <img
                src={card.imageSrc || defaultCardImage}
                style={{ aspectRatio: "4/3" }}
                alt=""
              />
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
