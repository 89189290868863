import React from "react";
import tw from "twin.macro"; 
import { css } from "styled-components/macro"; 
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
import Hero from "components/hero/EducationHero.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
// import Footer from "components/footers/MiniCenteredFooter.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import HarshRaut from 'images/harshRaut.png';
import Shreya from 'images/shreya.png';
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Portfolio />
    <MainFeature />
    <Features />
    <MainFeature2 />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our students <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Our students' love speaks volumes about the quality of our education. It's the ultimate testament to our commitment to excellence."
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc: HarshRaut,
          quote:
            "Grateful for the transformative experience at Pragmatiq Inc, gaining valuable skills in Camunda application and PERN stack web development under the guidance of Rajesh Sir and Archana Mam. Looking forward to staying connected & wishing Pragmatiq Inc. continued success.",
          customerName: "Harsh Raut",
          customerTitle: "RGIT Mumbai, India."
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc: Shreya,
          quote:
            "The internship from Pragmatiq improved my mobile app development skills from the intial stages, although I acknowledge the need for further practice. Overall, it was a great experience, and I truly believe what I learned will benefit my future career in this field.",
          customerName: "Shreya Jaggavarapu",
          customerTitle: "GITAM, Vizag,India"
        },
      ]}
      textOnLeft={true}
    />
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Do you have <span tw="text-primary-500">Questions ?</span>
        </>
      }
    />
    {/* <Blog /> */}
    {/* <ContactUsForm /> */}
    <Footer />
  </AnimationRevealPage>
);
