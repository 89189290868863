import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

import ceoinsights from "../../images/ceoinsights.png";
import iscr from "../../images/iscr.jpg";
import officeImage from "../../images/ofceventimage.png";
import holiImage from "../../images/holi.jpg";
import ramnavamiimage from "../../images/ramnavami.jpg";
import purplegenlaunchimage from "../../images/pgl.jpg";
import anniversaryImage from "../../images/ofc4thanniversaryImage.png";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm lg:max-w-xs`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-no-repeat object-cover h-80 bg-center lg:h-64 rounded`,
]);
const Category = tw.div`mt-4 text-secondary-100 font-bold text-sm`;
const Title = tw.h4`mt-2 leading-relaxed font-bold text-lg`;
const Link = tw.a`inline-block mt-2 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const blogPosts = [
    {
      imageSrc: ceoinsights,
      category: "CEO Insights - India",
      title: "Top 10 leaders from Telangana Startups",
      url: "https://www.ceoinsightsindia.com/leader/bharat-kumar-reddy-gujavarti-an-industry-leader-holding-extensive-experience-in-the-it-services-sectors-cid-5879.html#",
      backgroundSize: {},
    },
    {
      imageSrc: iscr,
      category: "Conferences",
      title: "ISCR 2023 - Technology in Clinical Research",
      url: "http://iscrconference2023.org/",
      backgroundSize: {},
    },
    {
      // imageSrc: 'https://media.licdn.com/dms/image/D5622AQH6mqYjREVEXg/feedshare-shrink_1280/0/1698645613466?e=1701907200&v=beta&t=WTYr1G95g61f__bNqf8vvry2xn8__Ebr45cFXw6r3qs',
      imageSrc: purplegenlaunchimage,
      category: "App Launch",
      title: "The education revolution is here. Experience our product 'Purplegene'.",
      url: "https://www.linkedin.com/feed/update/urn:li:activity:7183675330153361409",
      backgroundSize: { backgroundSize: "cover" },
    },
   
    {
      // imageSrc: 'https://media.licdn.com/dms/image/D5622AQH6mqYjREVEXg/feedshare-shrink_1280/0/1698645613466?e=1701907200&v=beta&t=WTYr1G95g61f__bNqf8vvry2xn8__Ebr45cFXw6r3qs',
      imageSrc: ramnavamiimage,
      category: "Events",
      title: "Marked Ram Navami with joy and learning",
      url: "https://www.linkedin.com/feed/update/urn:li:activity:7186629346168594433",
      backgroundSize: { backgroundSize: "cover" },
    },
   
    {
      // imageSrc: 'https://media.licdn.com/dms/image/D5622AQH6mqYjREVEXg/feedshare-shrink_1280/0/1698645613466?e=1701907200&v=beta&t=WTYr1G95g61f__bNqf8vvry2xn8__Ebr45cFXw6r3qs',
      imageSrc: holiImage,
      category: "Events",
      title: "Relive the vibrant colors and joyous camaraderie of our recent Holi celebrations",
      url: "https://www.linkedin.com/feed/update/urn:li:activity:7177904222808551424",
      backgroundSize: { backgroundSize: "cover" },
    },
    {
      // imageSrc: 'https://media.licdn.com/dms/image/D5622AQH6mqYjREVEXg/feedshare-shrink_1280/0/1698645613466?e=1701907200&v=beta&t=WTYr1G95g61f__bNqf8vvry2xn8__Ebr45cFXw6r3qs',
      imageSrc: officeImage,
      category: "Events",
      title: "Celebrations of Dussehra together, fostering an atmosphere of unity and cultural respect.",
      url: "https://www.linkedin.com/posts/pragmatiq-systems_teamspirit-happyatwork-companyculture-activity-7124636097787166722-YgDT?utm_source=share&utm_medium=member_desktop",
      backgroundSize: { backgroundSize: "cover" },
    },
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>News Articles & Events</HeadingTitle>
          <HeadingDescription>
            Things we would like to share with you!
          </HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image style={post.backgroundSize} imageSrc={post.imageSrc} />
                <Category>{post.category}</Category>
                <Title>{post.title}</Title>
                <Link href={post.url}>Read Post</Link>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
