import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import Footer from "components/footers/SimpleFiveColumn.js";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Refund Policy" }) => {
  return (
    <>
      <AnimationRevealPage>
        <Header />

        <Container>
          <ContentWithPaddingXl>
            <HeadingRow>
              <Heading style={{ fontSize: "2rem" }}>{headingText} </Heading>
            </HeadingRow>
            <h6 style={{ fontSize: "1rem" }}>Last updated on Jan 22nd 2024</h6>
            <Text style={{ textAlign: "justify", lineHeight: "1" }}>
              <p>
                Thank you for visiting at Artifexareana.
                Please read this policy carefully. This is the Return and Refund
                Policy of Purplegene app under Artifex events.
              </p>
              <p
                style={{
                  color: "green",
                  fontFamily: "cursive",
                  fontSize: "1.5rem",
                }}
              >
                Physical products
              </p>
              <p>
                We do not issue refunds for physical products once an order has
                been completed on our App.
              </p>
              <br />
              <p style={{ lineHeight: "1.5" }}>
                Products not listed under the vendor name of ‘Pragmatiq Systems
                Private Limited’ will be understood to be owned, sourced,
                procured, and sold by a third party using the name as given in
                the vendor description. The delivery process and delivery time
                will vary according to the third party’s method. For any issues,
                the third party vendor will need to be contacted directly.
              </p>
              <br />
              <p style={{ lineHeight: "1.5" }}>
                For products listed under the vendor name of ‘Pragmatiq System
                Private Limited’, we recommend contacting us for assistance if
                you experience any issues receiving or using products purchased
                on our App.
              </p>
              <p>Here's the key point: You are in control of your data.</p>
              <p
                style={{
                  color: "green",
                  fontFamily: "cursive",
                  fontSize: "1.5rem",
                }}
              >
                {" "}
                Digital products
              </p>
              <p>
                We do not issue refunds for digital products once an order has
                been completed on our  App.
              </p>
              <br />
              <p>
                We recommend contacting us for assistance if you experience any
                issues receiving or downloading our products.
              </p>
              <br />
              <p
                style={{
                  color: "green",
                  fontFamily: "cursive",
                  fontSize: "1.5rem",
                }}
              >
                How to contact us
              </p>
              <p>
                If you have any questions about our Returns and Refunds Policy,
                please contact us:
                <br />
                By Email : support@artifexarena.com
              </p>
            </Text>
          </ContentWithPaddingXl>
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
