import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import Footer from "components/footers/SimpleFiveColumn.js";

import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
// import OurMission from 'images/ourMissionImage.png';
import OurMission from "images/ourMissionlatest.png";
import OurVision from "images/ourVision.png";

import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
// const TwoColumn = tw.div`flex flex-row  justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const TwoColumn = styled.div((props) => [
  tw`mt-10 md:flex justify-center items-center max-w-screen-xl mx-auto py-20 md:py-24 items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
]);

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const CategoriesSubheading = tw.h1`text-3xl font-bold`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-justify text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
export default () => {
  const messages = [
    {
      subheading: "Our Vision",
      heading: "To carve a niche with innovative solutions.",
      description:
        "We envision to disrupt and revolutionize the Education, Healthcare, and Agriculture sectors by leveraging cutting-edge technology to foster sustainable development. Our disruptive approach, Pragmatiq Sustainability, embodies our commitment to practical and effective solutions. Through this, we aspire to empower individuals and businesses to thrive in the digitally connected world and bring about positive change in these industries.",
      imageSrc: OurVision,
      flexDirection: "row-reverse",
    },
    {
      subheading: "Our Mission",
      heading: "To create a niche with our product platforms.",
      description:
        "Our goal is to empower customers in making informed decisions through highly-personalized Pragbots (Pragmatiq AI-powered personal assistants). We are dedicated to providing tailored assistance for enhanced decision-making be it regarding education and career, lifestyle and well-being, or agricultural and farming practices.",
      imageSrc: OurMission,
      flexDirection: "row",
    },
  ];

  return (
    <AnimationRevealPage>
      <Header />
      {/* <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="To carve a niche with innovative solutions."//"To create a niche with our product platforms."
        description="We envision to disrupt and revolutionize the Education, Healthcare, and Agriculture sectors by leveraging cutting-edge technology to foster sustainable development. Our disruptive approach, Pragmatiq Sustainability, embodies our commitment to practical and effective solutions. Through this, we aspire to empower individuals and businesses to thrive in the digitally connected world and bring about positive change in these industries."//"To create disruption in Education, Healthcare and Agriculture through technology that enables sustainable development, which we call Pragmatiq Sustainability."
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Mission</Subheading>}
        heading="We aim to transform with our simplified solutions"//"We aim to disrupt with our solutions."
        description= "Our goal is to empower customers in making informed decisions through highly-personalized Pragbots (Pragmatiq AI-powered personal assistants). We are dedicated to providing tailored assistance for enhanced decision-making be it regarding education and career, lifestyle and well-being, or agricultural and farming practices, ."//"We will help our customers take well informed decisions with the help of highly-personalized pragbots (pragmatiq, intelligent ai powered personal assistants) about their education and career, life style and well-being or agricultural and farming practices. "
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      /> */}
      {messages.map((messages, index) => {
        return (
          <Container key={index}>
            <TwoColumn reversed={index % 2 === 1}>
              <ImageColumn>
                <Image src={messages.imageSrc} />
                {DecoratorBlob && <DecoratorBlob css={DecoratorBlob} />}
              </ImageColumn>
              <TextColumn>
                <TextContent>
                  <Subheading>{messages.subheading}</Subheading>
                  <Heading>{messages.heading}</Heading>
                  <Description>{messages.description}</Description>
                  {/* <PrimaryButton buttonRounded={buttonRounded} as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton> */}
                </TextContent>
              </TextColumn>
            </TwoColumn>
          </Container>
        );
      })}
      <Features
        subheading={
          <Subheading style={{ textAlign: "center" }}>Our Values</Subheading>
        }
        heading="A testament to our core beliefs"
        description="Our values are a Validation to the culture and processes we abide by. Embracing our core values provides us with guidance, significance, and a sense of purpose" //"Following our core values gives us direction, meaning and purpose."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Reliable Tech Partner",
            description:
              "We are committed to providing continuous support to our clientele, offering assistance with strategy, advice, and business challenges.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Innvoation @ the core",
            description:
              "Continuous Innovation unlocks the potential to deliver top-tier products at affordable prices", //"Continuous Innovation is the key to create high quality products at affordable cost to our customers."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Client-Centric", //"Customer First",
            description:
              "Our unwavering customer-first approach enables us to consistently make the right choices for our valued clients, fostering a mindset that prioritizes customer needs and on-time deliveries", //"We do the right thing for the customer and ensure everyone in Pragmatiq has the same mindset to do so."
          },
        ]}
        linkText=""
      />
      <TeamCardGrid
        subheading={
          <Subheading style={{ textAlign: "center" }}>Our Team</Subheading>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
};
